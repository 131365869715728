class UTMHandler {
  constructor() {
    this.config = (typeof utmConfig !== "undefined" && utmConfig) || {};
    this.WEBSITE_DOMAINS = this.config.WEBSITE_DOMAINS || [];
    this.CONSTANTS = {
      paramsAdded: "paramsAdded",
    };
    this.init();
  }

  init() {
    this.addUTMParamsToAllLinks();
    this.activateEvents();
  }

  activateEvents() {
    this.mutationAction();
  }

  mutationAction() {
    const onMutation = (mutations) => {
      var links = [];
      for (var i = 0; i < mutations.length; i++) {
        var addedNodes = mutations[i].addedNodes;
        for (var j = 0; j < addedNodes.length; j++) {
          var node = addedNodes[j];
          if (node.tagName === "A") {
            links.push(node);
          } else if (node.firstElementChild && node.querySelector("a")) {
            links.push(...node.querySelectorAll("a"));
          }
        }
      }

      if (links.length) {
        this.addUTMParamsToAllLinks();
      }
    };

    onMutation([
      {
        addedNodes: [document.body],
      },
    ]);

    // process the future modifications
    const mo = new MutationObserver(onMutation);
    mo.observe(document, { subtree: true, childList: true });
  }

  addUTMParamsToAllLinks() {
    const mainURLParams = this.getMainURLParams();
    const $allLinks = this.getAllElements();

    $allLinks.forEach(($ele) => {
      const existingParams = this.getElementExistingParams($ele);
      const totalParams = { ...mainURLParams, ...existingParams };
      const totalParamsObj = new URLSearchParams(totalParams);
      const totalParamsString = totalParamsObj.toString();
      const eleHref = $ele.getAttribute("href");
      const hrefHasHash = eleHref.includes("#");
      const hrefHasParams = Object.keys(totalParams).length;

      const domainWithoutHashAndParams = () => {
        const domainBeforeHash = eleHref.split("#")[0];
        const domainBeforeParams = domainBeforeHash.split("?")[0];
        console.log(domainBeforeParams);
        return domainBeforeParams;
      };

      const hrefHash = hrefHasHash ? eleHref.split("#")[1] : "";
      const newHref = `${domainWithoutHashAndParams()}${
        hrefHasParams ? "?" : ""
      }${totalParamsString}${hrefHasHash ? "#" : ""}${hrefHash}`;
      $ele.setAttribute("href", newHref);
      $ele.setAttribute(this.CONSTANTS.paramsAdded, true);
    });
  }

  getAllElements() {
    const domainsToFilter = [
      this.getCurrentWebsiteDomain(),
      ...this.WEBSITE_DOMAINS,
    ];

    const $ancorTags = document.querySelectorAll(
      `a:not([href^='#']):not([href^='mailto:']):not([href^='tel:']):not([href^='javascript:']):not([${this.CONSTANTS.paramsAdded}])`
    );

    const $filteredAncorTags = [...$ancorTags].filter((tag) => {
      const href = tag.getAttribute("href");

      if (!href) return false;

      const hasDomain = domainsToFilter.some((domain) => {
        const hrefStartsWithSlash = href.startsWith("/");
        if (hrefStartsWithSlash) return true;
        return href.includes(domain);
      });

      if (!hasDomain) return false;

      return true;
    });

    return $filteredAncorTags;
  }

  getMainURLParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const allURLParamsObj = {};

    for (let [key, value] of urlParams.entries()) {
      allURLParamsObj[key] = value;
    }

    return allURLParamsObj;
  }

  getElementExistingParams($ele) {
    const eleHref = $ele.getAttribute("href");
    const eleHrefHasParams = eleHref.includes("?");

    if (!eleHrefHasParams) return {};

    const eleParams = eleHref.split("?")[1];
    const eleParamsObj = new URLSearchParams(eleParams);

    let totalParamsObj = {};

    for (let [key, value] of eleParamsObj.entries()) {
      totalParamsObj[key] = value;
    }

    return totalParamsObj;
  }

  getCurrentWebsiteDomain() {
    return window.location.origin;
  }
}

new UTMHandler();
